<template>
  <bryntum-gantt
    ref="gantt"
    v-bind="ganttConfig"
    :tasks="tasks"
    :dependencies="dependencies"
    :features="features"
  />
</template>

<script>
import { BryntumGantt, BryntumTaskEditor } from '@bryntum/gantt-vue'
import mixin from '@/components/InterfaceEditor/components/mixins'

import ruLocale from '@bryntum/gantt/locales/gantt.locale.Ru'
import engLocale from '@bryntum/gantt/locales/gantt.locale.En'
import { DateHelper, Fullscreen, AjaxHelper, Toast } from '@bryntum/gantt'
import FilterBuilder, { buildFilters, EComponentTypes } from '@/components/InterfaceEditor/components/utils'

import zipcelx from 'zipcelx'

let gantt, input, sendBtn

export default {
  name: 'a-gantt-bryntum',
  mixins: [mixin],
  components: {
    BryntumGantt,
    ruLocale,
    engLocale,
    DateHelper,
    AjaxHelper,
    Toast,
    BryntumTaskEditor
  },

  props: {
    editorAlias: {
      type: String,
      description: 'Псевдоним'
    },
    config: {
      type: Object,
      editor: 'GanttBryntum',
      default: () => {
        return {
          editorProperties: {
            taskSource: {
              entityType: null,
              entityId: null,
              planStartDate: null,
              planEndDate: null,
              factStartDate: null,
              factEndDate: null,
              keyField: null,
              nameField: null,
              parentIdField: null,
              percentDoneField: null,
              filters: [],
              isManuallyScheduledField: null,
              duration: null,
              effort: null,
              constraintType: null,
              constraintDate: null,
              inactive: null,
              hierarchyNumber: null,
              indexNumber: null
            },
            tableSource: {
              entityType: null,
              entityId: null,
              columns: []
            },
            tasksRelation: {
              entityType: null,
              entityId: null,
              keyField: null,
              fromAttr: null,
              toAttr: null,
              type: null
            },
            isDiagramEditing: true,
            defaultValuesForTask: [],
            chartItemAction: {
              card: {
                registryId: null
              }
            }
          }
        }
      }
    }
  },
  data () {
    return {
      ganttConfig: {
        height: '100%',
        tbar: {},
        selectionMode: {
          cell: true,
          dragSelect: true,
          rowNumber: true
        },
        taskStore: {
          tree: true,
          transformFlatData: true,
          wbsMode: 'auto'
        }
      },
      tasks: [],
      dependencies: [],
      // ругается, что нельзя юзать так и не реактивно (но по факту реактивно)
      features: {
        baselines: {
          disabled: true
        },
        progressLine: {
          disabled: true,
          statusDate: new Date()
        },
        parentArea: {
          disabled: true
        },
        labels: {
          disabled: true,
          left: {
            field: 'name'
          }
        },
        dependencyEdit: {
          disabled: false
        },
        mspExport: {
          filename: 'Gantt Export'
        },
        fillHandle: {
          disabled: false
        },
        excelExporter: {
          disabled: false,
          zipcelx,
          dateFormat: 'YYYY-MM-DD HH:mm'
        },
        taskEdit: {
          items: {
            resourcesTab: null,
            notesTab: null
          }
        }
      },
      //
      revertedAttrsNameForColumn: [],
      revertedAttrsNameForDependencies: [],
      // костыли попросили для типов
      dependencyType: ['', 0, 1, 2, 3],
      constraintType: [
        '',
        'finishnoearlierthan',
        'finishnolaterthan',
        'mustfinishon',
        'muststarton',
        'startnoearlierthan',
        'startnolaterthan'
      ],
      dependenciesForDelete: [],
      tasksForDelete: []
    }
  },
  async mounted () {
    gantt = await this.$refs.gantt.instance
    gantt.localeManager.locale = ruLocale
    gantt.accentProperties = this.config.editorProperties

    gantt.dependencyStore.on({
      remove: (event) => {
        event.records.map(record => {
          this.dependenciesForDelete.push(record)
        })
      }
    })

    gantt.taskStore.on({
      remove: (event) => {
        if (!event.isMove) {
          event.records.map(record => {
            this.tasksForDelete.push(record)
          })
        }
      }
    })

    await this.addControlsOnToolbar()
    await this.addDefaultColumns()

    if (this.config.editorProperties.taskSource.entityId) {
      this.tasks = await this.getRowData(
        this.config.editorProperties.taskSource.entityId,
        this.config.editorProperties.taskSource.entityType
      )
    }

    if (this.config.editorProperties.tableSource.columns.length > 0) {
      await this.prepareColumnsFromSource(
        this.config.editorProperties.tableSource.columns
      )
    }

    if (this.config.editorProperties.tasksRelation.entityId) {
      await this.getTaskRelations(
        this.config.editorProperties.tasksRelation.entityId,
        'registry'
      )
    }

    input = gantt.widgetMap.input
    sendBtn = gantt.widgetMap.sendBtn
  },
  methods: {
    async getRowData (entityId, sourceType) {
      let result
      let response
      let filters = this.config.editorProperties.taskSource.filters
      let buildFilters = this.getFilters(filters)

      switch (sourceType) {
        case 'query':
          response = await this.$http.post(
            `${this.$config.api}/datawarehouseservice/query/${entityId}`,
            typeof buildFilters.where !== 'undefined' ? buildFilters : null,
            { hideNotification: true }
          )
          result = this.prepareDataFromSource(response.data)
          break
        case 'registry':
          response = await this.$http.post(
            `${this.$config.api}/registryservice/registry/${entityId}`,
            typeof buildFilters.where !== 'undefined' ? buildFilters : null,
            { hideNotification: true }
          )
          result = this.prepareDataFromSource(response.data.data)
          break
      }

      return result
    },
    async prepareDataFromSource (data) {
      let keyField = this.config.editorProperties.taskSource.keyField
      let planStartDate = this.config.editorProperties.taskSource.planStartDate
      let planEndDate = this.config.editorProperties.taskSource.planEndDate
      let factStartDate = this.config.editorProperties.taskSource.factStartDate
      let factEndDate = this.config.editorProperties.taskSource.factEndDate
      let name = this.config.editorProperties.taskSource.nameField
      let parentId = this.config.editorProperties.taskSource.parentIdField
      let percentDone = this.config.editorProperties.taskSource.percentDoneField
      let isManuallyScheduled = this.config.editorProperties.taskSource.isManuallyScheduledField
      let duration = this.config.editorProperties.taskSource.duration
      let effort = this.config.editorProperties.taskSource.effort
      let constraintType = this.config.editorProperties.taskSource.constraintType
      let constraintDate = this.config.editorProperties.taskSource.constraintDate
      let inactive = this.config.editorProperties.taskSource.inactive

      this.revertedAttrsNameForColumn[keyField] = 'id'
      this.revertedAttrsNameForColumn[planStartDate] = 'baselines.startDate'
      this.revertedAttrsNameForColumn[planEndDate] = 'baselines.endDate'
      this.revertedAttrsNameForColumn[factStartDate] = 'startDate'
      this.revertedAttrsNameForColumn[factEndDate] = 'endDate'
      this.revertedAttrsNameForColumn[name] = 'name'
      this.revertedAttrsNameForColumn[parentId] = 'parentId'
      this.revertedAttrsNameForColumn[percentDone] = 'percentDone'
      this.revertedAttrsNameForColumn[isManuallyScheduled] = 'manuallyScheduled'
      this.revertedAttrsNameForColumn[duration] = 'duration'
      this.revertedAttrsNameForColumn[effort] = 'effort'
      this.revertedAttrsNameForColumn[constraintType] = 'constraintType'
      this.revertedAttrsNameForColumn[constraintDate] = 'constraintDate'
      this.revertedAttrsNameForColumn[inactive] = 'inactive'

      return data.map(item => {
        return {
          id: item[keyField],
          startDate: DateHelper.parse(item[factStartDate]),
          endDate: DateHelper.parse(item[factEndDate]),
          parentId: item[parentId],
          name: item[name],
          percentDone: item[percentDone],
          baselines: [
            {
              startDate: DateHelper.parse(item[planStartDate]),
              endDate: DateHelper.parse(item[planEndDate])
            }
          ],
          manuallyScheduled: item[isManuallyScheduled],
          duration: item[duration],
          effort: item[effort],
          constraintType: this.constraintType[item[constraintType]],
          constraintDate: item[constraintDate],
          inactive: item[inactive],
          record_data: item
        }
      })
    },
    getFilters (filters) {
      let model = typeof this.getModel() === 'object' ? this.getModel() : {}
      if (typeof filters !== 'undefined' && filters.length > 0) {
        const builder = new FilterBuilder(
          filters,
          model,
          this.$store,
          EComponentTypes.ganttNew
        )

        return {
          where: {
            and: builder.buildAsApiQl()
          }
        }
      }
      return {}
    },
    async normalizeGanttDataForSaving (data, isSameDependencySource = true) {
      let keyField = this.config.editorProperties.taskSource.keyField
      let planStartDate = this.config.editorProperties.taskSource.planStartDate
      let planEndDate = this.config.editorProperties.taskSource.planEndDate
      let factStartDate = this.config.editorProperties.taskSource.factStartDate
      let factEndDate = this.config.editorProperties.taskSource.factEndDate
      let name = this.config.editorProperties.taskSource.nameField
      let parentId = this.config.editorProperties.taskSource.parentIdField
      let percentDone = this.config.editorProperties.taskSource.percentDoneField
      let isManuallyScheduled = this.config.editorProperties.taskSource.isManuallyScheduledField
      let duration = this.config.editorProperties.taskSource.duration
      let effort = this.config.editorProperties.taskSource.effort
      let constraintType = this.config.editorProperties.taskSource.constraintType
      let constraintDate = this.config.editorProperties.taskSource.constraintDate
      let inactive = this.config.editorProperties.taskSource.inactive
      let objectId = this.config.editorProperties.taskSource.entityId
      let indexNumber = this.config.editorProperties.taskSource.indexNumber
      let hierarchyNumber = this.config.editorProperties.taskSource.hierarchyNumber

      let dependencyFromAttr = this.config.editorProperties.tasksRelation.fromAttr
      let dependencyToAttr = this.config.editorProperties.tasksRelation.toAttr
      let type = this.config.editorProperties.tasksRelation.type

      let result = data.map(item => {
        let id = parseInt(item.id) ? item.id : null
        let parsedParentId = parseInt(item.parentId) ? item.parentId : null
        let normalizedItem = {}

        if (id !== null) {
          normalizedItem[keyField] = id
          normalizedItem[factStartDate] = new Date(item.startDate).toDateString()
          normalizedItem[factEndDate] = new Date(item.endDate).toDateString()
          normalizedItem[parentId] = parsedParentId
          normalizedItem[name] = item.name
          normalizedItem[percentDone] = item.percentDone
          normalizedItem[isManuallyScheduled] = item.manuallyScheduled
          normalizedItem[duration] = item.duration
          normalizedItem[effort] = item.effort
          normalizedItem[constraintType] = item.constraintType
          normalizedItem[constraintDate] = item.constraintDate
          normalizedItem[inactive] = item.inactive
          normalizedItem[hierarchyNumber] = item.wbsValue.value
          normalizedItem[indexNumber] = item.sequenceNumber
          normalizedItem['object_id'] = objectId
        } else {
          normalizedItem[keyField] = id
          normalizedItem['object_id'] = objectId
          normalizedItem[factStartDate] = new Date(item.startDate).toDateString()
          normalizedItem[factEndDate] = new Date(item.endDate).toDateString()
          normalizedItem[parentId] = parsedParentId
          normalizedItem[name] = item.name
          normalizedItem[percentDone] = item.percentDone
          normalizedItem[isManuallyScheduled] = item.manuallyScheduled
          normalizedItem[duration] = item.duration
          normalizedItem[effort] = item.effort
          normalizedItem[constraintType] = item.constraintType
          normalizedItem[constraintDate] = item.constraintDate
          normalizedItem[inactive] = item.inactive
          normalizedItem[hierarchyNumber] = item.wbsValue.value
          normalizedItem[indexNumber] = item.sequenceNumber
          this.config.editorProperties.defaultValuesForTask.map(defaultValue => {
            normalizedItem[defaultValue.alias] = this.getModel()[defaultValue.attribute]
          })
        }

        /*if (isSameDependencySource) {
          let dependencies = item.dependencies
          if (dependencies.length > 0) {
            normalizedItem[dependencyFromAttr] = dependencies[0].fromTask?.id ?? null
            normalizedItem[dependencyToAttr] = dependencies[0].toTask?.id ?? null
            normalizedItem[type] = dependencies[0].type ?? null
          } else {
            normalizedItem[dependencyFromAttr] = null
            normalizedItem[dependencyToAttr] = null
            normalizedItem[type] = null
          }
        }*/

        return normalizedItem
      })

      if (this.tasksForDelete.length > 0) {
        this.tasksForDelete.map(item => {
          let normalizedItem = {}
          normalizedItem[keyField] = parseInt(item.id) ? item.id : null
          normalizedItem['object_id'] = objectId
          normalizedItem['is_deleted'] = true

          result.push(normalizedItem)
        })
      }

      return result
    },
    async recordsButchSave (records) {
      this.$http.post(
        `${this.$config.api}/registryservice/registry/records/bulk_save`,
        {
          records: JSON.stringify(records)
        }
      )
    },
    async prepareColumnsFromSource (columns) {
      columns.map(async column => {
        let ganttColumn = {
          type: column.ganttColumnType,
          field: this.revertedAttrsNameForColumn[column.value],
          text: column.text,
          name: column.value
        }

        ganttColumn = await this.addPropertiesOnColumnType(ganttColumn)

        gantt.columns.grid.columns.add(ganttColumn)
      })
    },
    async getTaskRelations (entityId, sourceType) {
      let result
      let response

      switch (sourceType) {
        case 'registry':
          response = await this.$http.post(
            `${this.$config.api}/registryservice/registry/${entityId}`,
            null,
            { hideNotification: true }
          )
          result = this.prepareRelations(response.data.data)
          break
      }
    },
    async prepareRelations (dependencies) {
      let fromIdAttr = this.config.editorProperties.tasksRelation.fromAttr
      let toIdAttr = this.config.editorProperties.tasksRelation.toAttr
      let type = this.config.editorProperties.tasksRelation.type
      let keyField = this.config.editorProperties.tasksRelation.keyField

      this.revertedAttrsNameForDependencies[fromIdAttr] = 'fromTask'
      this.revertedAttrsNameForDependencies[toIdAttr] = 'toTask'
      this.revertedAttrsNameForDependencies[type] = 'type'
      this.revertedAttrsNameForDependencies[keyField] = 'id'

      this.dependencies = dependencies.map(dependency => {
        return {
          id: dependency[keyField],
          fromTask: dependency[fromIdAttr],
          toTask: dependency[toIdAttr],
          type: this.dependencyType[dependency[type]]
        }
      })
    },
    async addPropertiesOnColumnType (column) {
      switch (column.type) {
        case 'percentdone':
          column.showCircle = true
          break
      }
      return column
    },
    async normalizeGanttDataDependenciesDataForSaving (data) {
      let keyField = this.config.editorProperties.tasksRelation.keyField
      let fromTask = this.config.editorProperties.tasksRelation.fromAttr
      let toTask = this.config.editorProperties.tasksRelation.toAttr
      let type = this.config.editorProperties.tasksRelation.type
      let objectId = this.config.editorProperties.tasksRelation.entityId

      let result = data.map(item => {
        let normalizedItem = {}
        normalizedItem[keyField] = parseInt(item.id) ? item.id : null
        normalizedItem[fromTask] = item.fromTask?.id
        normalizedItem[toTask] = item.toTask?.id
        normalizedItem[type] = item.type
        normalizedItem['object_id'] = objectId

        return normalizedItem
      })

      if (this.dependenciesForDelete.length > 0) {
        this.dependenciesForDelete.map(item => {
          let normalizedItem = {}
          normalizedItem[keyField] = parseInt(item.id) ? item.id : null
          normalizedItem['object_id'] = objectId
          normalizedItem['is_deleted'] = true

          result.push(normalizedItem)
        })
      }

      return result
    },
    async disableDiagramEditing () {
      gantt.features.dependencies.allowCreate = false
      gantt.features.percentBar.allowResize = false
      gantt.features.cellEdit.disabled = true
      gantt.features.taskEdit.disabled = true
      gantt.features.taskMenu.disabled = true
      gantt.features.taskDrag.disabled = true
      gantt.features.taskResize.disabled = true
      gantt.features.taskDragCreate.disabled = true
    },
    async addControlsOnToolbar () {
      gantt.tbar.add([
        // Добавляет задачу
        {
          type: 'buttonGroup',
          items: [
            {
              color: 'b-green',
              ref: 'addTaskButton',
              icon: 'b-fa b-fa-plus',
              text: 'Добавить',
              tooltip: 'Создать новую задачу',
              onAction: () => {
                let added = gantt.taskStore.rootNode.appendChild({ name: gantt.L('New task'), duration: 1 })
                gantt.project.commitAsync()
                gantt.scrollRowIntoView(added)
                gantt.features.cellEdit.startEditing({
                  record: added,
                  field: 'name'
                })
              }
            }
          ]
        },
        // откатывает сделанное действие
        {
          ref: 'undoRedo',
          type: 'undoredo',
          items: {
            transactionsCombo: null
          }
        },
        // кнопки свернуть/развернуть
        {
          type: 'buttonGroup',
          items: [
            {
              ref: 'expandAllButton',
              icon: 'b-fa b-fa-angle-double-down',
              tooltip: 'Развернуть все',
              onAction: () => {
                gantt.expandAll()
              }
            },
            {
              ref: 'collapseAllButton',
              icon: 'b-fa b-fa-angle-double-up',
              tooltip: 'Свернуть все',
              onAction: () => {
                gantt.collapseAll()
              }
            }
          ]
        },
        // кнопки зума
        {
          type: 'buttonGroup',
          items: [
            {
              ref: 'zoomInButton',
              icon: 'b-fa b-fa-search-plus',
              tooltip: 'Приблизить',
              onAction: () => {
                gantt.zoomIn()
              }
            },
            {
              ref: 'zoomOutButton',
              icon: 'b-fa b-fa-search-minus',
              tooltip: 'Отдалить',
              onAction: () => {
                gantt.zoomOut()
              }
            },
            {
              ref: 'zoomToFitButton',
              icon: 'b-fa b-fa-compress-arrows-alt',
              tooltip: 'Масштаб по содержимому',
              onAction: () => {
                gantt.zoomToFit({
                  leftMargin: 50,
                  rightMargin: 50
                })
              }
            },
            {
              ref: 'previousButton',
              icon: 'b-fa b-fa-angle-left',
              tooltip: 'Предыдущий интервал',
              onAction: () => {
                gantt.shiftPrevious()
              }
            },
            {
              ref: 'nextButton',
              icon: 'b-fa b-fa-angle-right',
              tooltip: 'Следующий интервал',
              onAction: () => {
                gantt.shiftNext()
              }
            }
          ]
        },
        // меню настроек
        {
          ref: 'settings',
          text: 'Настройки',
          menu: [
            {
              text: 'Язык',
              menu: [
                {
                  type: 'button',
                  text: 'Russian',
                  onClick: () => {
                    gantt.localeManager.locale = ruLocale
                  }
                },
                {
                  type: 'button',
                  text: 'English',
                  onClick: () => {
                    gantt.localeManager.locale = engLocale
                  }
                }
              ]
            },
            {
              text: 'Экспорт',
              menu: [
                {
                  type: 'button',
                  text: 'MSP',
                  icon: 'b-fa-file-export',
                  onClick: () => {
                    const filename = gantt.project.taskStore.first && `${gantt.project.taskStore.first.name}.xml`
                    gantt.features.mspExport.export({
                      filename
                    })
                  }
                },
                {
                  type: 'button',
                  text: 'Excel',
                  ref: 'excelExportBtn',
                  icon: 'b-fa-file-export',
                  onAction: () => {
                    const filename = gantt.project.taskStore.first && gantt.project.taskStore.first.name
                    gantt.features.excelExporter.export({
                      filename
                    })
                  }
                }
              ]
            },
            {
              text: 'Критические пути',
              checked: false,
              onToggle: () => {
                gantt.features.criticalPaths.disabled = !gantt.features.criticalPaths.disabled
              }
            },
            {
              text: 'Линии проекта',
              checked: true,
              onToggle: () => {
                gantt.features.projectLines.disabled = !gantt.features.projectLines.disabled
              }
            },
            {
              text: 'Исходные планы',
              checked: false,
              onToggle: () => {
                gantt.features.baselines.disabled = !gantt.features.baselines.disabled
              }
            },
            {
              text: 'Линия прогресса',
              checked: false,
              onToggle: () => {
                gantt.features.progressLine.disabled = !gantt.features.progressLine.disabled
              }
            },
            {
              text: 'Показать линии столбцов',
              checked: true,
              onToggle: () => {
                gantt.features.columnLines.disabled = !gantt.features.columnLines.disabled
              }
            },
            {
              text: 'Показать родительские области',
              checked: false,
              onToggle: () => {
                gantt.features.parentArea.disabled = !gantt.features.parentArea.disabled
              }
            },
            {
              text: 'Показать зависимости',
              checked: true,
              onToggle: () => {
                gantt.features.dependencies.disabled = !gantt.features.dependencies.disabled
              }
            },
            {
              text: 'Показать названия',
              checked: false,
              onToggle: () => {
                gantt.features.labels.disabled = !gantt.features.labels.disabled
              }
            },
            {
              text: 'Спрятать календарь',
              cls: 'b-separator',
              checked: false,
              onToggle: () => {
                gantt.subGrids.normal.collapsed = !gantt.subGrids.normal.collapsed
              }
            }
          ]
        },
        // выбор даты старта проекта
        {
          type: 'datefield',
          ref: 'startDateField',
          label: 'Старт проекта',
          flex: '0 0 17em',
          onChange: ({ value, oldValue }) => {
            if (value) {
              gantt.startDate = DateHelper.add(value, -1, 'week')
              gantt.project.setStartDate(value)
            }
          }
        },
        // фильтр статический (по конкретному столбцу)
        {
          type: 'textfield',
          ref: 'filterByName',
          cls: 'filter-by-name',
          flex: '0 0 13.5em',
          placeholder: 'Найти по названию',
          clearable: true,
          keyStrokeChangeDelay: 100,
          triggers: {
            filter: {
              align: 'end',
              cls: 'b-fa b-fa-filter'
            }
          },
          onChange: ({ value }) => {
            if (value === '') {
              gantt.taskStore.clearFilters()
            } else {
              value = value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
              gantt.taskStore.filter({
                filters: task => task.name && task.name.match(new RegExp(value, 'i')),
                replace: true
              })
            }
          }
        },
        {
          type: 'filepicker',
          ref: 'input',
          buttonConfig: {
            text: 'Выберите файл для импорта (mpp)',
            icon: 'b-fa-folder-open'
          },
          listeners: {
            change: ({ files }) => {
              sendBtn.disabled = files.length === 0
            },
            clear: () => {
              sendBtn.disable()
            }
          }
        },
        {
          type: 'button',
          ref: 'sendBtn',
          text: 'Импортировать xml',
          cls: 'b-load-button b-blue',
          icon: 'b-fa-file-import',
          disabled: true,
          onClick: this.onImportButtonClick
        }
      ])

      if (this.config.editorProperties.isDiagramEditing) {
        gantt.tbar.add(
          // сохранить изменения по всем строкам
          {
            type: 'button',
            text: 'Сохранить',
            onClick: async () => {
              let data = await this.normalizeGanttDataForSaving(gantt.taskStore.allRecords)
              await this.recordsButchSave(data)

              if (gantt.accentProperties.taskSource.entityId !== gantt.accentProperties.tasksRelation.entityId) {
                let dependencies = await this.normalizeGanttDataDependenciesDataForSaving(gantt.dependencyStore.allRecords)
                await this.recordsButchSave(dependencies)
              }
            }
          })
      } else {
        gantt.tbar.add(
          {
            type: 'button',
            text: 'Сохранение недоступно',
            cls: 'b-transparent'
          })
      }

      gantt.tbar.add(
        {
          type: 'button',
          cls: 'b-icon-fullscreen b-icon',
          onClick: () => {
            if (Fullscreen.isFullscreen) {
              Fullscreen.exit()
            } else {
              Fullscreen.request(gantt)
            }
          }
        }
      )
    },
    async onImportButtonClick () {
      const { files } = input

      if (files) {
        const formData = new FormData()
        formData.append('request', files[0])
        gantt.maskBody('Импортирование проекта ...')
        this.$http.post(`${this.$config.api}/xsd2jsonconverter/convert/mpp`, formData)
          .then(async response => {
            if (response.data.success && response.data.parameters) {
              const { project } = gantt
              let data = JSON.parse(response.data.parameters)

              await this.importData(data)

              project.destroy()

              gantt.setStartDate(gantt.project.startDate)
              await gantt.scrollToDate(gantt.project.startDate, { block: 'start' })

              input.clear()
              gantt.unmaskBody()

              Toast.show('Импорт успешно выполнен!')
            } else {
              Toast.show({
                html: `Ошибка импорта: ${response.data.message}`,
                color: 'b-red',
                style: 'color:white',
                timeout: 3000
              })
            }
          }).catch(response => {
            Toast.show({
              html: `Ошибка импорта: ${response.error || response.message}`,
              color: 'b-red',
              style: 'color:white',
              timeout: 3000
            })
          })
      }
    },
    async importData (data) {
      let me = this
      let project = new gantt.projectModelClass({
        silenceInitialCommit: false
      })

      gantt.project = project
      gantt.calendarManager = project.calendarManagerStore
      gantt.taskStore = project.taskStore
      gantt.assignmentStore = project.assignmentStore
      gantt.resourceStore = project.resourceStore
      gantt.dependencyStore = project.dependencyStore

      Object.assign(gantt, {
        calendarMap: {},
        resourceMap: {},
        taskMap: {}
      })

      me.importCalendars(data)

      const tasks = me.getTaskTree(Array.isArray(data.tasks) ? data.tasks : [data.tasks])

      me.importResources(data)
      me.importAssignments(data)

      gantt.taskStore.rootNode.appendChild(tasks[0].children)

      me.importDependencies(data)

      me.importProject(data)

      // Assign the new project to the gantt before launching commitAsync()
      // to let Gantt resolve possible scheduling conflicts
      gantt.project = project

      await gantt.project.commitAsync()

      me.importColumns(data)

      return project
    },
    // начало функций импорта mpp файлов
    importResources (data) {
      gantt.resourceStore.add(data.resources.map(this.processResource, gantt))
    },
    processResource (data) {
      const { id } = data
      delete data.id
      data.calendar = gantt.calendarMap[data.calendar]
      const resource = new gantt.resourceStore.modelClass(data)
      gantt.resourceMap[id] = resource
      return resource
    },
    importDependencies (data) {
      gantt.dependencyStore.add(data.dependencies.map(this.processDependency, gantt))
    },
    processDependency (data) {
      const me = gantt
      const { fromEvent, toEvent } = data
      delete data.id
      const dep = new me.dependencyStore.modelClass(data)
      dep.fromEvent = me.taskMap[fromEvent].id
      dep.toEvent = me.taskMap[toEvent].id
      return dep
    },
    importAssignments (data) {
      gantt.assignmentStore.add(data.assignments.map(this.processAssignment, gantt))
    },
    processAssignment (data) {
      const me = gantt
      delete data.id
      return new me.assignmentStore.modelClass({
        units: data.units,
        event: me.taskMap[data.event],
        resource: me.resourceMap[data.resource]
      })
    },
    getTaskTree (tasks) {
      return tasks.map(this.processTask, gantt)
    },
    processTask (data) {
      const me = gantt
      const { id, children } = data

      delete data.children
      delete data.id
      delete data.milestone

      data.calendar = me.calendarMap[data.calendar]

      const t = new me.taskStore.modelClass(data)

      if (children) {
        t.appendChild(this.getTaskTree(children))
      }

      t._id = id
      me.taskMap[t._id] = t
      return t
    },
    processCalendarChildren (children) {
      return children.map(this.processCalendar, gantt)
    },
    processCalendar (data) {
      const me = gantt
      const { id, children } = data
      const intervals = data.intervals

      delete data.children
      delete data.id

      const t = new me.calendarManager.modelClass(Object.assign(data, { intervals }))

      if (children) {
        t.appendChild(this.processCalendarChildren(children))
      }

      t._id = id
      me.calendarMap[t._id] = t

      return t
    },
    importCalendars (data) {
      gantt.calendarManager.add(this.processCalendarChildren(data.calendars.children))
    },
    importColumns (data) {
      let columns = data.columns.map(this.processColumn, this).filter(column => column)
      const columnStore = gantt.subGrids.locked.columns

      if (!columns.length && gantt.defaultColumns) {
        columns = [
          { type: 'name', field: 'name', width: 250 },
          { type: 'addnew' }
        ]
      }

      if (columns.length) {
        columnStore.removeAll(true)
        columnStore.add(columns)
      }
    },
    processColumn (data) {
      const columnClass = gantt.columns.constructor.getColumnClass(data.type)

      // ignore unknown columns (or columns that classes are not loaded)
      if (columnClass) {
        return Object.assign({ region: 'locked' }, data)
      }
    },
    importProject (data) {
      if ('calendar' in data.project) {
        data.project.calendar = gantt.calendarMap[data.project.calendar]
      }
      Object.assign(gantt.project, data.project)
    },
    // конец функций импорта mpp файлов
    async addDefaultColumns () {
      gantt.subGrids.locked.columns.removeAll(true)

      let columns = [
        { type: 'rownumber' },
        { type: 'wbs' },
        { type: 'name', field: 'name' }
      ]

      gantt.subGrids.locked.columns.add(columns)
    }
  }
}
</script>

<style scoped>
@import "~@bryntum/gantt/gantt.stockholm.css";
</style>
