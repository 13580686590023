import { render, staticRenderFns } from "./a-gantt-bryntum.vue?vue&type=template&id=7789b5de&scoped=true&"
import script from "./a-gantt-bryntum.vue?vue&type=script&lang=js&"
export * from "./a-gantt-bryntum.vue?vue&type=script&lang=js&"
import style0 from "./a-gantt-bryntum.vue?vue&type=style&index=0&id=7789b5de&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7789b5de",
  null
  
)

export default component.exports